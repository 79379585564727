import React from 'react'
import PropTypes from 'prop-types'
import styled, {ThemeProvider as ScThemeProvider} from 'styled-components'
import {theme, muiThemeCurrent} from '@clearcapital/ui-resources'
import {Router, Switch, Route} from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import Footer from 'components/Footer'
import ParticleAnimation from 'components/LoginAnimation/AnimationWrapper'
import history from 'services/history'
import {MuiThemeProvider} from '@material-ui/core'
import logo from 'assets/ClearCollateral.png'

const formatDate = (date) => {
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short', // "Feb"
    day: 'numeric', // "1"
    year: 'numeric', // "2025"
    hour: 'numeric', // "11"
    minute: '2-digit', // "00"
    hour12: true, // 12-hour format
    timeZoneName: 'short', // "PT"
  }).format(date)

  // Split and rearrange the formatted date for the correct output
  const [monthDay, year, time] = formattedDate.split(', ')

  // Return the formatted date in the desired format
  return `${monthDay}, ${year} at ${time}`
}

const ScheduledMaintenance = ({maintenanceEnd}) => {
  const renderContent = () => {
    return (
      <ScheduledMaintenance.Styled>
        <div className='main-content'>
          <img
            data-sqa-id='CC-Logo'
            className='auth-logo-image'
            src={logo}
            alt='ClearCollateral Platform Logo'
          />
          <h1 className='title'>Scheduled Maintenance</h1>
          <p className='message'>
            We are undergoing scheduled maintenance. The app will be available{' '}
            {formatDate(maintenanceEnd)}.
          </p>
          <a className='cc-link' href='https://clearcapital.com'>
            Go to clearcapital.com
          </a>
        </div>
        <ParticleAnimation />
        <Footer />
      </ScheduledMaintenance.Styled>
    )
  }

  return (
    <MuiThemeProvider theme={muiThemeCurrent}>
      <CssBaseline />
      <ScThemeProvider theme={muiThemeCurrent}>
        <Router history={history}>
          <Switch>
            <Route path='*' render={renderContent} />
          </Switch>
        </Router>
      </ScThemeProvider>
    </MuiThemeProvider>
  )
}

ScheduledMaintenance.propTypes = {
  maintenanceEnd: PropTypes.number.isRequired,
}

ScheduledMaintenance.Styled = styled.div`
  height: 100%;
  width: 100%;
  display: flex;

  .main-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${theme.palette.colors.white};
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.04);
    height: 100%;
    min-height: 879px;
    min-width: 375px;
    padding-left: 48px;
    padding-right: 48px;
    width: 30vw;

    .auth-logo-image {
      width: 157px;
      margin-bottom: 24px;
    }

    .title {
      font-size: 1.875rem;
      font-weight: ${theme.typography.fontWeightLight};
      line-height: 35px;
      margin: 0;
      text-transform: none;
    }

    .message {
      font-size: 1rem;
      margin: 24px 0 16px 0;
    }

    a.cc-link {
      font-weight: 500;
      text-decoration: none;

      &:visited,
      :hover,
      :active {
        color: #6091e2;
      }
    }
  }
`

export default ScheduledMaintenance
