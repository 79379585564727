import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import {CaptureConsole, HttpClient} from '@sentry/integrations'
import ApplicationNode from './ApplicationNode'
import {unregister} from './services/registerServiceWorker'
import {loadState} from 'services/localStorage'
import {loadLocalState} from 'actions/authActions'
import store from 'services/store'
import WebFont from 'webfontloader'
import get from 'lodash/get'

// polyfills for IE11
// imports updated to follow recommendations here:
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md
import 'core-js/es/object'
import 'core-js/es/symbol'
import 'core-js/es/symbol/iterator'
import 'core-js/es/map'
import 'core-js/es/set'
import 'core-js/es/promise/finally'
import 'regenerator-runtime/runtime'
import 'core-js/stable/url-search-params'
import 'intersection-observer'
import 'core-js/es/string/repeat'
import 'core-js/es/weak-map'
import 'core-js/es/array'
import {getHostedEnv} from 'services/environment'
import {beforeSend} from 'services/sentryServices'
import {QA, UAT, PROD} from 'constants/environmentConstants'
import ScheduledMaintenance from 'views/ScheduledMaintenance'

WebFont.load({
  google: {
    families: ['Roboto:300,400,500,700,900', 'sans-serif'],
  },
  custom: {
    families: ['Inter:n4,n5,n6'],
    urls: ['https://rsms.me/inter/inter.css'],
  },
})

const env = getHostedEnv()
// Sentry can be temporarily enabled on localhost if necessary
//  for development but that change should not be committed
if (env && [QA, UAT, PROD].includes(env)) {
  // don't sent replays in NPE as our Sentry allotment for them is limited
  const replaysOnErrorSampleRate = env === PROD ? 1.0 : 0.0

  Sentry.init({
    dsn: 'https://e05117c329d4dc4a4a11ff259266e0b7@o236539.ingest.sentry.io/4505869019447296',
    environment: env,
    autoSessionTracking: true,
    normalizeDepth: 5,
    integrations: [
      new Sentry.Replay(),
      new CaptureConsole({levels: ['error']}),
      new HttpClient({
        failedRequestStatusCodes: [
          [400, 499],
          [500, 599],
        ],
        failedRequestTargets: [/.*/],
      }),
    ],
    replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend,
  })
}

const render = (Component) => {
  ReactDOM.render(<Component store={store} />, document.getElementById('root'))
}

const persistedState = loadState()

const scheduledMaintenanceStartInMS = Date.parse('2025-03-13T20:00:00.000-07:00') // March 13, 2025 08:00 PM Pacific
const scheduledMaintenanceEndInMS = Date.parse('2025-03-13T20:00:00.000-07:00') // March 13, 2025 08:00 PM Pacific
const nowInMS = Date.now()
const isScheduledMaintenance =
  nowInMS > scheduledMaintenanceStartInMS && nowInMS < scheduledMaintenanceEndInMS

if (isScheduledMaintenance) {
  ReactDOM.render(
    <ScheduledMaintenance maintenanceEnd={scheduledMaintenanceEndInMS} />,
    document.getElementById('root')
  )
} else if (get(persistedState, 'app.authentication.user')) {
  store.dispatch(loadLocalState(persistedState)).then(() => {
    render(ApplicationNode)
    unregister()
  })
} else {
  render(ApplicationNode)
  unregister()
}

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./ApplicationNode', () => {
    const NextApp = require('./ApplicationNode').default
    render(NextApp)
  })
}
